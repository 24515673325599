import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FETCH_DATA } from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useFetchData = ( id, client ) => {
    const [data, setData] = useState( null );
    const [isError, setIsError] = useState( false );
    const [isLoading, setIsLoading] = useState( true );
    const isFirstMount = useRef( true );

    useEffect( () => {
        if ( isFirstMount.current ) {
            if ( id ) {
                const fetchData = async () => {
                    setIsLoading( true );
                    try {
                        const response = await axios.get( `${ FETCH_DATA }?id=${ id }&client=${ client }` );
                        setData( response.data );
                    } catch ( error ) {
                        setIsError( true );
                        Sentry.captureException( error );
                    } finally {
                        setIsLoading( false );
                        isFirstMount.current = false;
                    }
                };

                fetchData();
            }
        }
    }, [id] ); // Add id to the dependency array

    return { data, isLoading, isError };
};

export default useFetchData;
