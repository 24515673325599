import { useState, useCallback } from 'react';
import axios from 'axios';
import { BASE_URL, READ_SCHOOL } from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useFetchSchoolData = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const fetchData = useCallback( async ( institute_code ) => {
        setIsLoading( true );
        try {
            const API_URL = `${ BASE_URL }${ READ_SCHOOL }${ institute_code }`;
            const res = await axios.get( API_URL );
            setResponse( res.data );
            return res.data;
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    }, [] );

    return { fetchData, response, error, isLoading };
};

export default useFetchSchoolData;
