import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    BASE_URL, FORM
} from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useFetchCourses = () => {
    const [data, setData] = useState( null );
    const [isError, setIsError] = useState( false );
    const [isLoading, setIsLoading] = useState( true );
    const isFirstMount = useRef( true ); // Initialize a useRef

    useEffect( () => {
        if ( isFirstMount.current ) {

            const fetchData = async () => {
                setIsLoading( true );
                try {
                    const response = await axios.get( `https://auth.strideahead.in/profile/streams-and-specializations/options` );
                    setData( response.data );
                } catch ( error ) {
                    setIsError( true );
                    Sentry.captureException( error );
                } finally {
                    setIsLoading( false );
                }
            };

            fetchData();

            isFirstMount.current = false; // Set it to false after the first valid API call
        }
    }, [] ); // Dependency on id, so the effect runs whenever id changes

    return { data, isLoading, isError };
};

export default useFetchCourses;
