import { useState } from "react";
import axios from "axios";
import { BASE_URL, TEST_SERIES_DETAILS } from "../utils/constants/constants";
import * as Sentry from '@sentry/react';

const useFetchTestSeriesDetails = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FETCH_DATA = `${ BASE_URL }/${ TEST_SERIES_DETAILS }`

    const FetchTestSeriesDetails = async ( testSeriesId ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ FETCH_DATA }${ testSeriesId }` );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchTestSeriesDetails };
};

export default useFetchTestSeriesDetails;