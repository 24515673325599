import { useState } from "react";
import axios from "axios";
import { BASE_URL, FETCH_SUBJECTS } from "../utils/constants/constants";
import * as Sentry from '@sentry/react';

const useFetchAllSubjects = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const API_URL = `${ BASE_URL }${ FETCH_SUBJECTS }`

    const FetchSubjects = async () => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ API_URL }` );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchSubjects };
};

export default useFetchAllSubjects;