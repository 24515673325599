import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, CREATE_SCHOOL
} from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useCreateSchoolCode = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [isCalled, setIsCalled] = useState( false );
    const API_URL = `${ BASE_URL }${ CREATE_SCHOOL }`;

    const createSchoolCode = async ( userSchoolName, userAddress, contactPersonName, contactPersonPhone, userEmail, examinationMode, responseId, clientName ) => {
        if ( isCalled ) {
            console.warn( "createSchoolCode has already been called." );
            return;
        }

        setIsLoading( true );
        setIsCalled( true );
        const requestBody = {
            name: userSchoolName,
            address: userAddress,
            contact_person_name: contactPersonName,
            contact_person_phone: contactPersonPhone,
            email: userEmail,
            examination_mode: examinationMode,
            response_id: responseId,
            client: clientName
        };

        try {
            const res = await axios.post( API_URL, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, createSchoolCode };
};

export default useCreateSchoolCode;