import { useState } from 'react';
import axios from 'axios';
import { BASE_URL, GENERATE_OTP_EMAIL } from '../utils/constants/constants';
import * as Sentry from '@sentry/react';

const useRequestOTPEmail = () => {
    const [emailData, setEmailData] = useState( null );
    const [isRequestingOTP, setIsRequestingOTP] = useState( false );
    const [errorOTP, setErrorOTP] = useState( null );

    const requestOTP = async ( Email, Client ) => {
        setIsRequestingOTP( true );
        setErrorOTP( null );

        try {
            const GENERATE_OTP_EMAIL_API_URL = `${ BASE_URL }${ GENERATE_OTP_EMAIL }?user_email=${ Email }&client_code=${ Client }`;
            const response = await axios.get( GENERATE_OTP_EMAIL_API_URL );
            setEmailData( response.data );
            setIsRequestingOTP( false );
        } catch ( err ) {
            setErrorOTP( err );
            setIsRequestingOTP( false );
            Sentry.captureException( err );
        }
    };

    return { emailData, isRequestingOTP, errorOTP, requestOTP };
};

export default useRequestOTPEmail;
