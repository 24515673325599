import { useState } from 'react';
import axios from 'axios';
import { BASE_URL, PUT_VERIFY_STUDENT } from "../utils/constants/constants";
import * as Sentry from '@sentry/react';

const useUpdateVerifyStudents = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const updateVerifyStudents = async (
        studentRollNumber,
        emailVerified,
        phoneVerified,
    ) => {

        setIsLoading( true );

        const requestBody = {
            email_verified: emailVerified,
            phone_verified: phoneVerified,
        };

        try {
            const res = await axios.put( `${ BASE_URL }${ PUT_VERIFY_STUDENT }${ studentRollNumber }/`, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, updateVerifyStudents };
};

export default useUpdateVerifyStudents;
