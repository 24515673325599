import { useState } from "react";
import axios from "axios";
import * as Sentry from '@sentry/react';

const useFetchIndustry = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FETCH_DATA = `https://f2uh9cc873.execute-api.ap-south-1.amazonaws.com/v1/fetch-industries`

    const FetchIndustry = async () => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ FETCH_DATA }` );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchIndustry };
};

export default useFetchIndustry;