import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
    BASE_URL,
    FETCH_FLOW_BLOCKS_BY_ID,
} from "../utils/constants/constants";
import * as Sentry from '@sentry/react';

const useFetchFlowBlocks = () => {

    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FetchFlowBlocks = async ( testId ) => {
        const FETCH_DATA = `${ BASE_URL }${ FETCH_FLOW_BLOCKS_BY_ID }`
        setIsLoading( true );

        try {
            const response = await axios.get( `${ FETCH_DATA }${ testId }/` );
            setResponse( response.data );
        } catch ( err ) {
            setError( err );
            Sentry.captureException( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchFlowBlocks };
};

export default useFetchFlowBlocks;
